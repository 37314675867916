import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from "@reach/router"
import Image from "./image"

const Header = ({ avatar, title, description }) => (
  <header className="logo">
    <Location>
      {({ location }) => {
        return location.pathname == "/" ? (
          <div>
            <Link to="/about/">{title}</Link>
            <span className="logo-prompt code">about me</span>
            <div>{description}</div>
          </div>
        ) : (
          <div>
            <Link to="/">{title}</Link>
            <span className="logo-prompt code">back to home</span>
            {location.pathname == "/about/" ? <div>{description}</div> : null}
          </div>
        )
      }}
    </Location>
  </header>
)

Header.propTypes = {
  avatar: PropTypes.string,
}

Header.defaultProps = {
  avatar: ``,
}

export default Header
